<template>
  <div>
    <b-overlay :show="branchSummaryReportLoading" rounded="sm">
      <div class="card">
        <div class="card-header">
          <h4>
            {{ $t("PAGES.DASHBOARD.MONTHLY_ORDER_STATUS") }}
            ( {{ branchSummaryReport.dateInterval }} )
          </h4>
        </div>
        <div class="card-body p-0">
          <apexchart
            type="bar"
            :options="branchSummaryReport.chartOptions"
            :series="branchSummaryReport.chartSeries"
            ref="summaryChart"
            height="430"
          ></apexchart>
          <div style="overflow-x: auto;">
            <table class="table datatable w-100" ref="datatable">
              <thead>
                <tr>
                  <th>{{ $t("COMPANY.BRANCH") }}</th>
                  <th class="text-center">
                    {{ $t("PAGES.DASHBOARD.CURRENCY_TYPE") }}
                  </th>
                  <th class="text-right">
                    {{ $t("PAGES.DASHBOARD.TOTAL_TRANSACTION_AMOUNT") }}
                  </th>
                  <!-- <th class="text-right">
                    {{ $t("PAGES.DASHBOARD.AMOUNT_PAID") }}
                  </th>
                  <th class="text-right">
                    {{ $t("PAGES.DASHBOARD.AMOUNT_AWAITING_PAYMENT") }}
                  </th> -->
                </tr>
              </thead>
              <tbody
                v-for="(branchData,
                branchIndex) in branchSummaryReport.tableBranchData"
                :key="branchIndex"
              >
                <tr
                  v-for="(branchItem, branchItemIndex) in branchData"
                  :key="branchItemIndex"
                >
                  <td class="border-0 font-weight-bold text-uppercase">
                    {{ branchItemIndex == 0 ? branchItem.branchTitle : "" }}
                  </td>
                  <td class="text-center">{{ branchItem.paymentUnit }}</td>
                  <td class="text-right">
                    {{ branchItem.totalPrice | getTablePriceValue }}
                  </td>
                  <!-- <td class="text-right">
                    {{ branchItem.payedPrice | getTablePriceValue }}
                  </td>
                  <td class="text-right">
                    {{ branchItem.waitingPrice | getTablePriceValue }}
                  </td> -->
                </tr>
              </tbody>
              <tbody>
                <tr
                  v-for="(summaryItem,
                  summaryItemIndex) in branchSummaryReport.tableSummaryData"
                  :key="summaryItemIndex"
                >
                  <td class="border-0 font-weight-bold text-uppercase">
                    {{
                      summaryItemIndex == 0
                        ? $t("PAGES.SALES_MANAGEMENT.LIST.TOTAL")
                        : ""
                    }}
                  </td>
                  <td class="text-center">{{ summaryItem.paymentUnit }}</td>
                  <td class="text-right">
                    {{ summaryItem.totalPrice | getTablePriceValue }}
                  </td>
                  <!-- <td class="text-right">
                    {{ summaryItem.payedPrice | getTablePriceValue }}
                  </td>
                  <td class="text-right">
                    {{ summaryItem.waitingPrice | getTablePriceValue }}
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapState } from "vuex";
import i18nService from "@/services/i18n.js";
import ApexEn from "@/config/i18n/apex-en.json";
import ApexTr from "@/config/i18n/apex-tr.json";

export default {
  name: "dashboard",
  components: {},
  computed: {
    ...mapState({
      loading: state => state.dashboard.loading
    }),
    dateInterval() {
      return this.from && this.to ? `${this.from} - ${this.to}` : "";
    }
  },
  data: function() {
    return {
      from: null,
      to: null,
      week: null,
      barChartOptions: {
        chart: {
          type: "donut",
          id: "vuechart-currencies",
          locales: [ApexTr, ApexEn],
          defaultLocale: i18nService.getActiveLanguage()
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toLocaleString();
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(value) {
            return value.toLocaleString();
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return value.toLocaleString();
            },
            title: {
              formatter: () => {
                return this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL");
              }
            }
          }
        }
      },
      barChartSeries: [
        {
          name: "series",
          data: []
        }
      ],
      donutChartSeries: [],
      donatChartOptions: {
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function(value) {
            return value.toLocaleString();
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return value.toLocaleString();
            },
            title: {
              formatter: () => {
                return this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL");
              }
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontWeight: 600,
                  color: "white",
                  offsetY: 40
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "white",
                  offsetY: 50,
                  formatter: function(val) {
                    const intVal = parseFloat(val);
                    const localeVal = intVal.toLocaleString();
                    return localeVal;
                  }
                }
              }
            }
          }
        }
      },
      branchSummaryReportLoading: false,
      branchSummaryReport: {
        dateInterval: "",
        chartSeries: [
          {
            name: "series",
            data: []
          }
        ],
        chartOptions: {
          chart: {
            type: "bar",
            id: "vuechart-summary",
            locales: [ApexTr, ApexEn],
            defaultLocale: i18nService.getActiveLanguage()
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top"
              }
            }
          },
          colors: ["#393d3f", "#2fb12f", "#f7af29"],
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"]
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"]
          },
          tooltip: {
            shared: true,
            intersect: false
          },
          xaxis: {
            categories: []
          }
        },
        tableBranchData: [],
        tableSummaryData: []
      },
      dashboardReportLoading: false
    };
  },
  methods: {
    parseFloatToFixedTwo(val) {
      if (val) {
        return parseFloat(parseFloat(val).toFixed(2));
      }
      return 0;
    },
    getLocaleDateString(dt) {
      return new Date(dt).toLocaleDateString(i18nService.getActiveLanguage());
    }
  },
  filters: {
    getTablePriceValue: function(value) {
      if (!value) return "0";
      return value;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("ROUTES.HOME") }]);
    if (this.$refs.summaryChart) {
      this.branchSummaryReportLoading = true;
      this.$store
        .dispatch("dashboard/GET_DASHBOARD_SUMMARY_REPORT")
        .then(res => {
          const {
            firstDayOfMonth,
            nowDate,
            summaryReport,
            branchSummaryRepor
          } = res;
          const branchSummaryTableData = branchSummaryRepor?.data || [];
          const summaryReportData =
            summaryReport?.data?.filter(val => !!val?.paymentUnit) || [];
          const payload = {
            dateInterval:
              firstDayOfMonth && nowDate
                ? `${this.getLocaleDateString(
                    firstDayOfMonth.slice(0, 10)
                  )} - ${this.getLocaleDateString(nowDate.slice(0, 10))}`
                : "",
            tableBranchData: branchSummaryTableData || [],
            tableSummaryData: summaryReportData || [],
            chartSeries: [
              {
                name: this.$t("PAGES.DASHBOARD.TOTAL_TRANSACTION_AMOUNT"),
                data: summaryReportData?.map(val =>
                  this.parseFloatToFixedTwo(val.totalPrice)
                )
              }
              /* {
                name: this.$t("PAGES.DASHBOARD.AMOUNT_PAID"),
                data: summaryReportData?.map(val =>
                  this.parseFloatToFixedTwo(val.payedPrice)
                )
              },
              {
                name: this.$t("PAGES.DASHBOARD.AMOUNT_AWAITING_PAYMENT"),
                data: summaryReportData?.map(val =>
                  this.parseFloatToFixedTwo(val.waitingPrice)
                )
              } */
            ],
            chartOptions: {
              ...this.branchSummaryReport.chartOptions,
              xaxis: {
                categories: summaryReportData?.map(val => val.paymentUnit)
              }
            }
          };
          this.branchSummaryReport = payload;
        })
        .catch(() => {})
        .finally(() => {
          this.branchSummaryReportLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 0;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 25px 35px 25px 30px;
}
</style>
